<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">一级菜单列表</div>
      <div>
        <div>
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='add'
            >+添加菜单</el-button
          >
        </div>
        <div>
          <!-- <el-select
            v-model="timeState"
            placeholder="状态筛选"
            clearable 
            class="cinput ac"
            style="margin: 0 23px"
            @change="list"
          >
            <el-option
              v-for="item in timeStateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="type"
            placeholder="类型筛选"
            clearable 
            class="cinput ac"
            style="margin: 0 23px"
            @change="list"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <!-- <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="请输入搜索内容"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div> -->
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
         @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      align='center'
      width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="weight" label="权重" align="center" >
        </el-table-column>
        <!-- <el-table-column prop="content" align="center" label="问卷类型" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div>
              {{scope.row.type==1?'小程序':'课程包'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" align="center" label="生效状态" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <div> 
              {{date>scope.row.gettime?'已开始':'未开始'}}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="name" align="center" label="名称" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="uri" align="center" label="路径" :show-overflow-tooltip="true" >
        </el-table-column>

        <el-table-column prop="address" label="操作" align="center" >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <!-- <el-link type="primary" :underline="false" @click='details(scope.row)'>详情</el-link> -->
              <el-link type="primary" :underline="false" @click='details(scope.row)' style="margin-left:20px">编辑</el-link>
              <el-link type="primary" :underline="false" @click='dele(scope.row)' style="margin:0 20px">删除</el-link>
              <el-link type="primary" v-if="!scope.row.uri" :underline="false" @click='child(scope.row)' >子菜单</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <!-- <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button> -->
        <!-- <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>
import {treeList2,deletMenu} from "../../apis/index";
export default {
  data() {
    return {
      date:'',
      timeState:'',
      timeStateList:[
        {
          id:1,
          name:'未生效'
        },
        {
          id:2,
          name:'已生效'
        },
        {
          id:3,
          name:'已过期'
        },
      ],
       typeList:[
                 {
                    id:1,
                    name:'小程序'
                },{
                    id:2,
                    name:'课程包'
                }
            ],
            type:'',//问卷类型
      pageIndex: 1,
      pageSize: 10,
      total:0,
      qslist:[],
      levelLists:[],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "1",
      input: "",
      tableData: [
       
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
     if(newdata==''){
        this.isshow=false
       this.input=''
       this.list()
     }else{
       this.isshow=true
     }
    },
    radio(newdata, olddata) {
     this.list()
    },
    value(){
      this.list()
    },
  },
  created(){
    this.getTreeList()
    // let date=new Date().toLocaleString( )
    // let date1=date.replace(/\//g,'-')
    // this.date=new Date(date1).getTime()
    // this.questype()
    // this.list()
    // this.leave()
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.path=='/exercisesAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  filters:{
    filtersDate(date){
     return new Date(date).getTime()
    },
  },
  methods: {
    async getTreeList(){
      // {roleId:this.roleId}
        const {data}=await treeList2()
        if(data.code==200){
            this.tableData=data.data.children
          //  let res=data.data.children.map(item=>{
        
          //       return item
          //   })
           
          //   this.list=res
            
        }
     },
    look(item){//查看统计
    console.log(item)
    let items=JSON.stringify(item)
      this.$router.push({path:'/surveyPapgeDetails2',query:{items}})
    },
   async leave() {
      //获取等级列表
      const { data } = await levelList({roleId:3});
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
   async list(){
      const params={
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        timeState:this.timeState,
        type:this.type
      }
     const {data}=await PaperList(params)
     if(data.code==200){
       let list=data.data.records.map(item=>{
         item.gettime=new Date(item.startTime).getTime()
         return item
       })
       this.tableData=list
       this.total=Number(data.data.total)
     }
    },
     async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist=data.data
      this.qslist = qslist;
    
    },
    add(){//添加菜单
      this.$router.push({path:'/menuAdd',query:{type:JSON.stringify('parent')}})
    },
    details(row){//查看详情
      this.$router.push({path:'/menuAdd',query:{type:JSON.stringify(row)}})
    },
    child(row){//子菜单列表
      this.$router.push({path:'/menuChild',query:{type:JSON.stringify(row)}})
    },
     details2(row){//添加试题
      this.$router.push({path:'/surveyPapgeAdd2',query:{row:JSON.stringify(row)}})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        console.log(val)
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
      },
    handleSizeChange(val) {
      this.pageSize=val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      console.log(`当前页: ${val}`);
      this.list()
    },
     dele(row) {
        const params={
         permissionId:row.permissionId,
       }
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           deletMenu(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getTreeList()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
       deleall() {
        this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px; 
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
